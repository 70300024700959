
  var editorScriptEntry = { editorReady: function () {} };

  

  
  var editorReadyWrapper = require('yoshi-flow-editor-runtime/build/esm/editorScript.js').editorReadyWrapper;
  var onEventWrapper = require('yoshi-flow-editor-runtime/build/esm/editorScript.js').onEventWrapper;
  var editorReady = editorScriptEntry.editorReady;
  var onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });

  var sentryConfig = {
    DSN: 'https://01fb0c955b864231aa35321b27af56a8@sentry.wixpress.com/440',
    id: '01fb0c955b864231aa35321b27af56a8',
    projectName: 'spotify',
    teamName: 'music',
  };

  var experimentsConfig = {"scope":"spotify"};

  var translationsConfig = null;

  var defaultTranslations = null;

  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady: editorReady,
      sentryConfig: sentryConfig,
      experimentsConfig: experimentsConfig,
      translationsConfig: translationsConfig,
      defaultTranslations: defaultTranslations,
      artifactId: 'spotify-ooi',
      optionalDeps: {
        
      },
    });
  }

  module.exports = editorScriptEntry.default || {
    ...editorScriptEntry,
    onEvent,
    editorReady,
  };
  
